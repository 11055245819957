import React from 'react';
import { Text } from '../components/text';
import { motion } from 'framer-motion';
import * as layoutStyles from '../components/css/new.module.css';

const IndexPage = (props) => {
  return (
    <motion.div
      key={props.location.pathname}
      variants={variants}
      initial='initial'
      animate='enter'
      exit='exit'
      className={`${layoutStyles.textContainer} ${layoutStyles.subContainer}`}
    >
      <p>
        <Text tid='aboutme_description' />
      </p>
    </motion.div>
  );
};

export default IndexPage;

const spring = {
  type: 'spring',
  stiffness: 500,
  damping: 30,
  duration: 0.5,
};

const variants = {
  initial: {
    x: -1600,
  },
  enter: {
    x: 0,
    transition: spring,
  },
  exit: {
    opacity: 0,
    x: 600,
    transition: { duration: 0.3 },
  },
};
